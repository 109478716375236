import { defineStore } from 'pinia';

import type { IGameProvider, IProvidersRequest } from '@skeleton/core/types';

interface IGameCollectionStoreState {
  cachedProviders: { [key: string]: IGameProvider[] };
  cacheKey: string
}

export const useGameProvidersStore = defineStore('gameProvidersStore', {
  state: (): IGameCollectionStoreState => ({
    cachedProviders: {},
    cacheKey: 'default',
  }),

  getters: {
    getFilteredProviders ({ cachedProviders, cacheKey }) {
        return cachedProviders[cacheKey]?.filter((provider) => !!provider.gameEnabledCount && provider.identity !== 'altenar-sportsbook') || [];
    },
  },

  actions: {
    setCashKey(defaultRequestParams: IProvidersRequest = {}) {
      this.cacheKey = defaultRequestParams ? JSON.stringify(defaultRequestParams) : 'default';
    },

    async getProviders(defaultRequestParams: IProvidersRequest = {}, noCash: boolean = false): Promise<IGameProvider[]> {
      const { getGameProviders } = useCoreGamesApi();

      this.setCashKey(defaultRequestParams)

      if (this.cachedProviders[this.cacheKey] && !noCash) {
        return this.cachedProviders[this.cacheKey];
      }

      try {
        const payload = await getGameProviders(defaultRequestParams);
        this.cachedProviders[this.cacheKey] = payload;
      } catch (error) {
        console.error('Error loading providers:', error);
        return [];
      }

      return this.cachedProviders[this.cacheKey];
    },
  },
});
